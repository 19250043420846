import { getRemainingTime } from "@/utils/helpers.js";


export const p2pTimerMixin = {
  data() {
    return {
      remainingTime: null,
      isLessThan1Min: false,
      timeRemainInterval: null,
      refreshDetailInterval: null,
    };
  },
  methods: {
    setupTimer() {
      this.loopUpdateTimer() // initial call
      if (this.timeRemainInterval != null) {
        clearInterval(this.timeRemainInterval)
        this.timeRemainInterval = null
      }
      this.timeRemainInterval = setInterval(() => {
        this.loopUpdateTimer()
      }, 1000)
      if (this.refreshDetailInterval != null) {
        clearInterval(this.refreshDetailInterval)
        this.refreshDetailInterval = null
      }
      this.refreshDetailInterval = setInterval(() => {
        this.getDetail() // refresh
      }, 5000)
    },
    loopUpdateTimer() {
      let toCompareTime = this.detail.transferredDate || this.detail.initiatedDate
    
      const timeObj = getRemainingTime(toCompareTime, 15*60)
    
      if (timeObj.remainingInSec <= 0) { 
        this.remainingTime = "00:00"
        this.isLessThan1Min = true
        this.getDetail() // refresh
      } else {
        this.remainingTime = timeObj.formatted
        this.isLessThan1Min = timeObj.isLessThan1Min
      }
    },
  },
  beforeDestroy() {
    clearInterval(this.timeRemainInterval)
    this.timeRemainInterval = null
    clearInterval(this.refreshDetailInterval)
    this.refreshDetailInterval = null
  },
}

