<template>

  <div v-loading="isLoading" id="transaction-detail">
    <el-row 
      :type="isMobile ? '' : 'flex'" 
      justify="space-between" 
      align="middle"
      class="instruction-timer-row"
      :class="{ completed: isCompleted }"
      :style="rowStyle"
    >
      <div class="title text-bold" v-html="title" />

      <el-row class="instruction-row" type="flex" align="middle" v-if="desc">
        <i 
          v-if="isCompleted"
          class="custom-icon mini tick-cross-icon"
          :class="{
            'icon-success-tick': isSuccess,
            'icon-failed-cross': !isSuccess
          }"
        />
        <span v-html="desc" />
      </el-row>

      <el-divider v-if="isMobile && !isCompleted" />

      <div
        v-if="!isCompleted" 
        class="remaining-time"
        :class="{ 
          'w-fit': !isMobile
        }"
      >
        <span>{{ $t("REMAINING_TIME") }}:</span>
        <span 
          class="timer" 
          :class="{ 
            'text-red': isLessThan1Min,
            'to-right': isMobile
          }"
        >
          <i class="custom-icon icon-clock mini text-center"/> <span>{{ remainingTime }}</span>
        </span>
      </div>
      
    </el-row>

    <el-row
      :type="isMobile ? '' : 'flex'"
      justify="space-between"
      align="stretch"
      class="steps-row"
    >
      <el-col 
        :span="isMobile ? 24 : 12" 
        class="deposit-step-wrapper" 
        :class="{ 'is-completed': isCompleted }"
      >
        <el-row :type="isMobile ? '' : 'flex'" justify="space-between" align="middle">
          <el-row type="flex" justify="start" align="middle">
            <div v-if="!isCompleted" class="step-circle"><span>1</span></div>
            <i 
              class="custom-icon"
              :class="{
                'icon-transaction-detail': isCompleted,
                'icon-deposit-money': !isCompleted
              }"
            />
            <div class="step-instruction">{{ $t(isCompleted ? "TRANSACTION_DETAILS" : "DEPOSIT_MONEY") }}</div>
          </el-row>

          <el-divider v-if="isMobile"/>

          <div class="cost-per-coin">
            <b>{{ $t("PRICE_PER_COIN") }}</b>: {{ currency }} {{ detail.price }}
          </div>
        </el-row>

        <el-divider v-if="!isMobile"/>

        <el-row class="info-row" :type="isMobile ? '':'flex'" justify="space-between" align="start" :gutter="40">
          <el-col>
            <div class="label">{{ $t("AMOUNT_TO_DEPOSIT") }}:</div>
            <div class="value-with-green-circle"><span>{{ amount }}</span></div>
          </el-col>
          <el-col>
            <div class="label">{{ $t("QUANTITY") }}:</div>
            <div class="text-green text-bold">{{ detail.coin }}</div>
          </el-col>
          <el-col>
            <div class="label">{{ $t("TRANSACTION_REFERENCE_ID") }}:</div>
            <div class="truncate-1">
              {{ refId }}
              <i
                class="custom-icon mini pointer icon-copy"
                @click="copy(refId)"
              />
            </div>
          </el-col>
        </el-row>

        <el-divider/>
        
        <div class="push-down-row w-100" v-if="isCompleted">
          <el-button 
            class="button green center-button"
            @click="() => goTo('/bo/dashboard')"
          >
            {{ $t("VIEW_UR_COIN_BALANCE") }}
          </el-button>
        </div>

        <template v-else> 
          <h3 class="text-primary">{{ leftDescHeader }}: </h3>

          <bank-details 
            class="info-row"
            v-if="opposingUser"
            :userObj="opposingUser"
            fullyExtend
          />

          <el-row v-if="detail.post && detail.post.remark" class="info-row" :type="isMobile ? '' : 'flex'" justify="space-between" align="start" :gutter="40">
            <el-col>
              <div class="label">{{ $t("REMARKS") }}:</div>
              <div><span>{{ detail.post.remark }}</span></div>
            </el-col>
          </el-row>

          <h3 class="text-primary text-center left-desc" v-html="leftDesc" />
        </template>

      </el-col>

      <el-col 
        :span="isMobile ? 24 : 12" 
        class="confirm-step-wrapper"
        :class="{ 'is-completed': isCompleted }"
      >
        <el-row type="flex" justify="start" align="middle">
          <div v-if="!isCompleted" class="step-circle"><span>2</span></div>
            <i 
              class="custom-icon"
              :class="{
                'icon-seller-detail': isCompleted,
                'icon-confirm-deposit': !isCompleted
              }"
            />
          <div class="step-instruction">{{ rightHeaderTitle }}</div>
        </el-row>

        <el-divider/>

        <div v-if="isCompleted">
          <bank-details 
            class="info-row"
            v-if="opposingUser"
            :userObj="opposingUser"
            fullyExtend
          />
          <el-row class="info-row" :type="isMobile ? '':'flex'" justify="space-between" align="start" :gutter="40">
            <el-col v-if="detail.post">
              <div class="label">{{ $t("MAX_NUM_OF_COIN") }}:</div>
              <div><span>{{ detail.post.initialCoin }}</span></div>
            </el-col>
            <el-col>
              <div class="label">{{ $t("PRICE_PER_COIN") }}:</div>
              <div><span>{{ currency }} {{ detail.price }}</span></div>
            </el-col>
            <el-col>
              <!-- PURPOSELY LEAVE BLANK -->
            </el-col>
          </el-row>

          <el-row v-if="detail.post && detail.post.remark" class="info-row" :type="isMobile ? '':'flex'" justify="space-between" align="start" :gutter="40">
            <el-col>
              <div class="label">{{ $t("REMARKS") }}:</div>
              <div><span>{{ detail.post.remark }}</span></div>
            </el-col>
          </el-row>
        </div>

        <template v-else>
          <div class="confirm-text" v-html="rightContent"/>

          <div class="w-100">
            <el-button 
              v-if="canDoAction"
              class="button green center-button"
              @click="() => handleApproveReject(true)"
            >
              {{ rightActionText }}
            </el-button>
          </div>
        </template>
      </el-col>

    </el-row>


    <page-footer
      v-if="canDoAction || isCompleted"
      :handleBackTo="isCompleted ? () => exit() : null"
      :handleComplain="handleComplain"
      :handleClear="isCompleted ? null : () => handleApproveReject(false)"
      :clearBtnText="$t(isInitiator ? 'CANCEL_TRANSACTION' : 'REJECT_TRANSACTION')"
      customClearClass="button red link-style"
    />

  </div>
</template>

<script>
import { fallbackCopyTextToClipboard } from "@/utils/helpers.js";
import { mapGetters } from "vuex";
import { generalMixin } from "@/utils/general-mixin.js";
import { p2pTimerMixin } from "@/utils/_mixins/p2p-timer.js";
import { getTransactionByRefId, updateTransaction } from "@/api/p2p-market"
import scssVariables from "@/assets/style/_variables.scss"
import BankDetails from './components/bank-details.vue';
import PageFooter from "@/components/page-footer.vue";

export default {
  name: "PendingDeposit",
  mixins: [generalMixin, p2pTimerMixin],
  components: {
    BankDetails, PageFooter
  },
  data() {
    return {
      isLoading: false,
      refId: null,
      detail: {},
      loopRefresh: null,
    }
  },
  computed: {
    ...mapGetters(["user"]),
    currency() {
      return this.detail?.post?.currency?.code?.toUpperCase() || ""
    },
    amount() {
      return `${this.currency} ${this.detail?.post?.price*this.detail.coin}`
    },
    isSeller() {
      return this.detail?.seller?.id === this.user.id
    },
    isSellPost() {
      return this.post?.type === "SELL"
    },
    isPending() {
      return this.detail?.transactionStatus === 'INITIATED'
    },
    isPendingDeposit() {
      return !this.detail?.transferredDate 
          && this.detail?.initiatedDate 
          && this.isPending
    },
    isPendingRelease() {
      return this.detail?.transferredDate && this.detail?.initiatedDate && this.isPending
    },
    isCompleted() {
      return !this.isPending
    },
    isSuccess() {
      return this.isCompleted && this.statusCode === "APPROVED"
    },
    statusCode() {
      return this.isPending ? 'PENDING' : this.detail?.transactionStatus
    },
    isInitiator() {
      return !this.isSellPost && this.detail?.buyer?.id === this.user.id
          || this.isSellPost && this.detail?.seller?.id === this.user.id
    },
    rowStyle() {
      if (!this.isCompleted || this.isLoading) {
        return null
      }

      const bgColor = this.isSuccess ? scssVariables.active : scssVariables.inactive
      return `background-color: ${bgColor}; color: white;`
    },
    title() {
      let textCode = null

      if (this.statusCode === "APPROVED") textCode = "TRANSACTION_DETAIL_HEADER_APPROVED"
      else if (this.statusCode === "EXPIRED") textCode = "TRANSACTION_DETAIL_HEADER_EXPIRED"
      else if (this.statusCode === "REJECTED") textCode = "TRANSACTION_DETAIL_HEADER_REJECTED"
      else if (this.statusCode === "CANCELLED") textCode = "TRANSACTION_DETAIL_HEADER_CANCELLED"

      else if (this.isSeller) {
        if (this.isPendingDeposit) {
          textCode = "TRANSACTION_DETAIL_HEADER_PENDING_DEPOSIT"
        } else {
          textCode = "RELEASE_WITHIN_15_MIN"
        }

      } else {
        if (this.isPendingDeposit) {
          textCode = "DEPOSIT_WITHIN_15_MIN"
        } else {
          textCode = "TRANSACTION_DETAIL_HEADER_PENDING_RELEASE"
        }
      }
      
      return textCode ? this.$t(textCode) : null
    },
    desc() {
      let textCode = null

      if (this.statusCode === "APPROVED") {
        if (this.isSeller) {
          textCode = "TRANSACTION_DETAIL_DESC_SELLER_APPROVED"
        } else {
          textCode = "TRANSACTION_DETAIL_DESC_BUYER_APPROVED"
        }
      }
      else if (this.statusCode === "EXPIRED") textCode = "TRANSACTION_DETAIL_DESC_EXPIRED"
      else if (this.statusCode === "REJECTED") textCode = "TRANSACTION_DETAIL_DESC_REJECTED"
      else if (this.statusCode === "CANCELLED") textCode = "TRANSACTION_DETAIL_DESC_CANCELLED"
      
      else if (this.isSeller) {
        if (this.isPendingDeposit) {
          textCode = "TRANSACTION_DETAIL_DESC_SELLER_PENDING_DEPOSIT"
        } else {
          textCode = "TRANSACTION_DETAIL_DESC_SELLER_PENDING_RELEASE"
        }

      } else {
        if (this.isPendingDeposit) {
          textCode = null
        } else {
          textCode = "TRANSACTION_DETAIL_DESC_BUYER_PENDING_RELEASE"
        }
      }

      return textCode ? this.$t(textCode) : null
    },
    opposingUser() {
      return this.isSeller ? this.detail?.buyer : this.detail?.seller
    },
    leftDescHeader() {
      let textCode = null

      if (this.isSeller) {
        textCode = "RECEIVE_MONEY_FROM"
        
      } else {
        textCode = "DEPOSIT_MONEY_TO"
      }

      return textCode ? this.$t(textCode) : null
    },
    leftDesc() {
      let textCode = null

      if (this.isSeller) {
        if (this.isPendingDeposit) {
          textCode = null
        } else {
          textCode = "RELEASE_CHECK_BANK_DETAILS"
        }

      } else {
        if (this.isPendingDeposit) {
          textCode = "DEPOSIT_PREFERRED_BANK"
        } else {
          textCode = null
        }
      }
      
      return textCode ? this.$t(textCode) : null
    },
    rightHeaderTitle() {
      let textCode = null

      if (this.isCompleted) {
        if (this.isSeller) {
          textCode = "BUYER_DETAILS"
        } else {
          textCode = "SELLER_DETAILS"
        }

      } else if (this.isSeller) {
        if (this.isPendingDeposit) {
          textCode = "PENDING"
        } else {
          textCode = "CONFIRM_RELEASE"
        }

      } else {
        if (this.isPendingDeposit) {
          textCode = "CONFIRM_DEPOSIT"
        } else {
          textCode = "PENDING"
        }
      }

      let result = textCode ? this.$t(textCode) : null
      if (textCode === "PENDING") result += "..."

      return result
    },
    rightContent() {
      let textCode = null
      let textObj = {}
      
      if (this.isSeller) {
        if (this.isPendingDeposit) {
          textCode = "ACTION_FROM_BUYER"
        } else {
          textCode = "CLICK_IF_RECEIVED"
          textObj = { action: this.$t('I_HAVE_RECEIVED') }
        }

      } else {
        if (this.isPendingDeposit) {
          textCode = "CLICK_IF_DEPOSITED"
          textObj = { action: this.$t('I_HAVE_DEPOSITED') }
        } else {
          textCode = "ACTION_FROM_SELLER"
        }
      }

      return textCode ? this.$t(textCode, textObj) : null
    },
    rightActionText() {
      let textCode = null
      let textObj = {}

      if (this.canDoAction) {
        if (this.isSeller) {
          textCode = "I_HAVE_RECEIVED"
        } else {
          textCode = "I_HAVE_DEPOSITED"
        }
      }

      return textCode ? this.$t(textCode, textObj) : null
    },

    canDoAction() {
      return (this.isSeller && !this.isPendingDeposit)
          || (!this.isSeller && this.isPendingDeposit)
    },
  },
  methods: {
    exit() {
      this.goTo("/bo/p2p-market?chosenTab=history")
    },
    handleComplain() {
     this.goTo(`/complain/p2p_transaction/${this.refId}`)
    },
    copy(value) {
      if (!navigator.clipboard) {
        fallbackCopyTextToClipboard(value);
      } else {
        navigator.clipboard.writeText(value);
      }
      this.$notify({
        message: this.$t("COPY_TO_CLIPBOARD"),
        duration: this.NOTIFY_DURATION,
        position: this.NOTIFY_POSITION
      });
    },
    async getDetail(isRefresh = false) {
      if (!isRefresh) this.isLoading = true
      await getTransactionByRefId(this.refId).then(res => {
        if (res.status != "ok") return
        console.log(res.result)
        this.detail = res.result

        if (
          (this.detail.seller.id !== this.user.id
          && this.detail.buyer.id !== this.user.id
          && this.isMember) // not his transaction, kick him out
        ) {
          this.exit()
          return
        }

        if (!this.isCompleted) {
          this.setupTimer()
        } else {
          clearInterval(this.loopRefresh)
          this.loopRefresh = null
          clearInterval(this.timeRemainInterval)
          this.timeRemainInterval = null
          clearInterval(this.refreshDetailInterval)
          this.refreshDetailInterval = null
        }

      })
      .finally(() => {
        if (!isRefresh) this.isLoading = false
      })
    },
    handleApproveReject(isApprove) {
      if (this.isLoading) return 
      let confirmMsgCode = "CONFIRMATION_DEPOSIT_MADE"
      if (!isApprove) {
        if (!this.canDoAction) return
        if (this.isInitiator) {
          confirmMsgCode = "CONFIRMATION_CANCEL"
        } else {
          confirmMsgCode = "CONFIRMATION_REJECT"
        }
      }
      const amount = `<b>${this.detail.post.currency.code.toUpperCase()} ${this.detail.post.price*this.detail.coin}</b>`

      this.$confirm(
        this.$t(confirmMsgCode, { amount }), 
        this.$t("ARE_YOU_SURE"), 
        { dangerouslyUseHTMLString: true }
      )
      .then(() => {
        this.isLoading = true
        updateTransaction(this.getPostData(isApprove))
        .then(() => {
          this.getDetail()
          // scroll to top
          document.body.scrollTop = 0; // For Safari
          document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        })
        .finally(() => {
          this.isLoading = false
        })
        
      })
      .catch(() => {})
    },
    getPostData(isApprove) {
      let result

      if (this.isPendingDeposit && !this.isSeller) {
        result = { 
          transactionRef: this.detail.transactionRef,
          transferred: isApprove
        }
      } else if (!this.isPendingDeposit && this.isSeller) {
        result = { 
          transactionRef: this.detail.transactionRef,
          released: isApprove
        }
      }
      return result
    }
  },

  async mounted() {
    this.refId = this.$route.params.refId
    await this.getDetail()

    if (!this.isCompleted) {
      if (this.loopRefresh) {
        clearInterval(this.loopRefresh)
        this.loopRefresh = null
      }
      this.loopRefresh = setInterval(() => {
        this.getDetail(true)
      }, 5000)
    }
  },

  beforeDestroy() {
    clearInterval(this.loopRefresh)
  }
}
</script>

<style lang="scss">
@import "@/assets/style/_variables.scss";
@import "@/assets/style/mixin/general.scss";
@include icon-colors;

#transaction-detail {
  .instruction-timer-row,
  .deposit-step-wrapper,
  .confirm-step-wrapper {
    background-color: $white;
    padding: 1rem 1.5rem;
    border-radius: 10px;
  }

  .instruction-timer-row {
    border: 1px dashed $primary;
    margin-bottom: 1rem;

    .title { 
      color: $primary;
    }
    &.completed {
      border: none;
      .title {
        color: inherit;
      }
    }
    
    .remaining-time {
      display: flex;
      align-items: center;
      .timer {
        margin-left: 0.5rem;
        font-weight: bold;
        color: #FFB127;
        font-size: 30px;
        &.text-red {
          .icon-clock {
            @extend .red-icon;
          }
        }
      }
    }
  }

  .tick-cross-icon {
    margin-right: 5px;
  }

  .deposit-step-wrapper,
  .confirm-step-wrapper {
    .custom-icon {
      margin-left: 1rem;
    }
  }

  .deposit-step-wrapper {
    margin-right: 1rem;
    .left-desc {
      margin-top: 2rem;
    }
  }
  .label {
    font-weight: bold;
    margin-bottom: 0;
    font-size: 0.8rem;
    color: #253858;
    line-height: 36px;
    +div {
      color: #767396;
      font-size: 0.9rem;
      &:not(.value-with-green-circle) {
        margin-top: 12px;
      }
    }
  }

  .confirm-step-wrapper {
    .confirm-text {
      color: #767396;
      font-size: 20px;
      line-height: 25px;
      text-align: center;
      padding: 7rem 0;
    }
  }
  .center-button {
    display: block;
    margin: auto;
  }

  .step-circle {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background-color: #D4D6DF;
    position: relative;
    text-align: center;
    span {
      position: absolute;
      top: 7px;
      left: 18px;
      font-size: 25px;
      line-height: 32px;
      color: #767396;
      font-weight: bold;
    }
  }

  .step-instruction {
    margin-left: 1rem;
    font-size: 25px;
    line-height: 32px;
    color: #767396;
    font-weight: bold;
  }

  .value-with-green-circle {
    padding: 5px 10px;
    background-color: #4CA020;
    width: fit-content;
    border-radius: 16px;
    >span {
      color: $white;
      font-size: 16px;
      line-height: 27px;
      font-weight: bold;
    }
  }

  .icon-copy {
    float: right;
  }

  .truncate-1 {
    @include truncateForRow(1);
  }

  .info-row {
    margin: 1rem 0;
  }

}

.mobile #transaction-detail {
  .cost-per-coin {
    margin-top: 1rem;
  }
  .instruction-timer-row {
    position: relative;
  }
  .instruction-row {
    margin-top: 1rem;
    >span {
      width: 90%;
    }
  }
  .remaining-time {
    justify-content: space-between;
  }
  .deposit-step-wrapper {
    margin-bottom: 1rem;
  }
  .confirm-step-wrapper {
    .confirm-text {
      padding: 3rem 0;
    }
  }
  .step-instruction {
    max-width: 60%;
  }
}
</style>